import axios, { AxiosInstance } from "axios";
import { serverUrl } from "./contants";

export default function getApiInstance(token?: string): AxiosInstance {
  if (token !== undefined) {
    const apiInstance = axios.create({
      baseURL: serverUrl,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return apiInstance;
  } else {
    const apiInstance = axios.create({
      baseURL: serverUrl,
    });
    return apiInstance;
  }
}
