import React, { useEffect, useState } from "react";
import DropDownMenu from "../common/DropDownMenu";
import styles from "./TermListScreen.module.css";
import { useTranslation } from "react-i18next";
import BasicMenu from "../common/BasicMenu";
import { BasicMenuButton } from "../common/BasicMenu";
import { SortType } from "../settings/SortType";
import { QuestionType } from "../settings/QuestionType";
import { PronunciationPosition } from "../settings/PronunciationPosition";
import {
  getPronPositioFromLocalStorage,
  getQuestionTypeFromLocalStorage,
  getSelectedGroupsFromLocalStorage,
  getSelectedLevelFromLocalStroage,
  getSortTypeFromLocalStorage,
  setPronPositionToLocalStorage,
  setQuestionTypeToLocalStorage,
  setSelectedGroupsToLocalStroage,
  setSelectedLevelsToLocalStroage,
  setSortTypeToLocalStroage,
} from "../settings/termListSettings";
import { makeSelectedGroupsString } from "../groups/groups";
import { TermLevel } from "./TermLevel";
import {
  getFilteredTermsWithOptions,
  getFilteredTermsWithQuery,
  makeSelectedLevelsString,
} from "./terms";
import TermListGrid from "./TermListGrid";
import Term from "./Term";
import OnevocaSearchInput from "../common/OnevocaSearchInput";
import { AddCircle, NorthRounded } from "@mui/icons-material";
import SmallButton from "../common/SmallButton";
import { motion, useAnimation } from "framer-motion";
import GroupSelectScreen from "../groups/GroupSelectScreen";
import Group from "../groups/Group";
import ListEmptyText from "../common/ListEmptyText";
import { useAppDispatch, useAppSelector } from "../../hook";
import { selectTerms } from "./termSlice";

interface TermListScreenProps {
  isLoading: boolean;
  handleOnClickLevelButton: (term: Term) => void;
  handleOnClickTermEditButton: (term: Term) => void;
  handleOnClickTermDeleteButton: (term: Term) => void;
  handleOnClickTermAddButton: () => void;
  handleOnClickShuffleButton: () => void;
  handleOnClickSelectAllButton: () => void;
  handleOnClickCancelSelectionAllButton: () => void;
  handleOnClickSelectedTermsGroupChangeButton: (terms: Term[]) => void;
  handleOnClickSelectedTermsLevelChangeButton: (
    e: React.MouseEvent<HTMLDivElement>,
    terms: Term[]
  ) => void;
  handleOnClickSelectedTermsDeleteButton: (terms: Term[]) => void;
}

export default function TermListScreen(props: TermListScreenProps) {
  const { t } = useTranslation();
  const controls = useAnimation();
  const [filteredTerms, setFilteredTerms] = useState<Term[]>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isVisibleScrollToTopButton, setIsVisibleScrollToTopButton] =
    useState(false);

  const rawTerms = useAppSelector(selectTerms);

  const [isSelectionMode, setIsSelectionMode] = useState(false);
  const [selectedTermsCount, setSelectedTermsCount] = useState(0);

  const [showGroupSelectScreen, setShowGroupSelectScreen] = useState(false);
  const [selectedGroups, setSelectedGroups] = useState<Group[]>([]);
  const [selectedLevels, setSelectedLevels] = useState<TermLevel[]>([]);
  const [sortType, setSortType] = useState(SortType.recent);
  const [questionType, setQuestionType] = useState(QuestionType.termFirst);
  const [pronPosition, setPronPosition] = useState(
    PronunciationPosition.onTerm
  );

  const [selectedGroupsString, setSelectedGroupsString] = useState("");
  const [selectedLevelsString, setSelectedLevelsString] = useState("");
  const [sortTypeString, setSortTypeString] = useState("");
  const [questionTypeString, setQuestionTypeString] = useState("");
  const [pronPositionTypeString, setPronPositionTypeString] = useState("");

  const [isOpenSelectedLevelMenu, setIsOpenSelectedLevelMenu] = useState(false);
  const [isOpenSortMenu, setIsOpenSortMenu] = useState(false);
  const [isOpenQuestionTypeMenu, setIsOpenQuestionTypeMenu] = useState(false);
  const [isOpenPronPositionMenu, setIsOpenPronPositionMenu] = useState(false);
  const [isOpenTermCellMoreMenu, setIsOpenTermCellMoreMenu] =
    useState<Term | null>(null);

  const [selectedLevelButtons, setSelectedLevelButtons] = useState<
    BasicMenuButton[]
  >([
    {
      title: t("LevelFilterAllTerms"),
      handleOnClose: () =>
        handleOnCloseSelectedLevelButton([
          TermLevel.difficult,
          TermLevel.familiar,
          TermLevel.perfect,
        ]),
    },
    {
      title: t("LevelFilterDifficult"),
      handleOnClose: () =>
        handleOnCloseSelectedLevelButton([TermLevel.difficult]),
    },
    {
      title: t("LevelFilterFamiliar"),
      handleOnClose: () =>
        handleOnCloseSelectedLevelButton([TermLevel.familiar]),
    },
    {
      title: t("LevelFilterLearning"),
      handleOnClose: () =>
        handleOnCloseSelectedLevelButton([
          TermLevel.difficult,
          TermLevel.familiar,
        ]),
    },
    {
      title: t("LevelFilterMemorized"),
      handleOnClose: () =>
        handleOnCloseSelectedLevelButton([TermLevel.perfect]),
    },
  ]);

  const [sortButtons, setSortButtons] = useState<BasicMenuButton[]>([
    {
      title: t("Latest"),
      handleOnClose: () => handleOnCloseSortButton(SortType.recent),
    },
    {
      title: t("Old"),
      handleOnClose: () => handleOnCloseSortButton(SortType.old),
    },
    {
      title: t("Alphabet"),
      handleOnClose: () => handleOnCloseSortButton(SortType.abc),
    },
  ]);
  const [questionTypeButtons, setQuestionTypeButtons] = useState<
    BasicMenuButton[]
  >([
    {
      title: t("TermListSettingCardStyleHideDefinition"),
      handleOnClose: () =>
        handleOnCloseQuestionTypeButton(QuestionType.termFirst),
    },
    {
      title: t("TermListSettingCardStyleHideTerm"),
      handleOnClose: () =>
        handleOnCloseQuestionTypeButton(QuestionType.definitionFirst),
    },
    {
      title: t("TermListSettingCardStyleShowAll"),
      handleOnClose: () =>
        handleOnCloseQuestionTypeButton(QuestionType.showAll),
    },
  ]);

  const [pronPositionButtons, setPronPositionButtons] = useState<
    BasicMenuButton[]
  >([
    {
      title: t("PronOnQuestion"),
      handleOnClose: () =>
        handleOnClosePronPositionButton(PronunciationPosition.onTerm),
    },
    {
      title: t("PronOnAnswer"),
      handleOnClose: () =>
        handleOnClosePronPositionButton(PronunciationPosition.onDefinition),
    },
  ]);

  const getTermCellMoreButtons = (): BasicMenuButton[] => [
    {
      title: t("Edit"),
      handleOnClose: () => {
        const term = isOpenTermCellMoreMenu;
        if (term !== null) {
          props.handleOnClickTermEditButton(term);
        }
        handleOnCloseTermCellMoreMenu();
      },
    },
    {
      title: t("Delete"),
      handleOnClose: () => {
        const term = isOpenTermCellMoreMenu;
        if (term !== null) {
          props.handleOnClickTermDeleteButton(term);
        }
        handleOnCloseTermCellMoreMenu();
      },
    },
  ];

  useEffect(() => {
    setSelectedGroups(getSelectedGroupsFromLocalStorage());
    setSelectedLevels(getSelectedLevelFromLocalStroage());
    setSelectedGroups(getSelectedGroupsFromLocalStorage());
    setSortType(getSortTypeFromLocalStorage());
    setQuestionType(getQuestionTypeFromLocalStorage());
    setPronPosition(getPronPositioFromLocalStorage());
  }, []);

  useEffect(() => {
    updateTermList();
  }, [rawTerms]);

  useEffect(() => {
    updateSelectedGroupsString(selectedGroups);
    updateSelectedLevelsString(selectedLevels);
    updateSortTypeString(sortType);
    updateQuestionTypeString(questionType);
    updatePronPositionString(pronPosition);
    updateTermList();
  }, [sortType, questionType, pronPosition, selectedGroups, selectedLevels]);

  useEffect(() => {
    updateSelectedTermsCount();
  }, [filteredTerms]);

  useEffect(() => {
    if (!isSelectionMode) {
      props.handleOnClickCancelSelectionAllButton();
    }
  }, [isSelectionMode]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  function handleScroll() {
    if (window.scrollY > 300) {
      setIsVisibleScrollToTopButton(true);
    } else {
      setIsVisibleScrollToTopButton(false);
    }
  }

  function scrollToTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  function updateSelectedTermsCount() {
    const count = filteredTerms.filter((term) => term.selected).length;
    setSelectedTermsCount(count);
  }

  function updateTermList() {
    setFilteredTerms(
      getFilteredTermsWithOptions(
        rawTerms,
        undefined,
        sortType,
        selectedGroups,
        selectedLevels
      )
    );
  }

  function updateSelectedGroupsString(selectedGroups: Group[]) {
    setSelectedGroupsString(makeSelectedGroupsString(selectedGroups));
  }

  function updateSelectedLevelsString(selectedLevels: TermLevel[]) {
    setSelectedLevelsString(makeSelectedLevelsString(selectedLevels));
  }

  function updateSortTypeString(sortType: SortType) {
    switch (sortType) {
      case SortType.recent:
        setSortTypeString(t("Latest"));
        break;
      case SortType.old:
        setSortTypeString(t("Old"));
        break;
      case SortType.abc:
        setSortTypeString(t("Alphabet"));
        break;
    }
  }

  function updateQuestionTypeString(questionType: QuestionType) {
    switch (questionType) {
      case QuestionType.termFirst:
        setQuestionTypeString(t("TermListSettingCardStyleHideDefinition"));
        break;
      case QuestionType.definitionFirst:
        setQuestionTypeString(t("TermListSettingCardStyleHideTerm"));
        break;
      case QuestionType.showAll:
        setQuestionTypeString(t("TermListSettingCardStyleShowAll"));
        break;
    }
  }

  function updatePronPositionString(pronPosition: PronunciationPosition) {
    switch (pronPosition) {
      case PronunciationPosition.onTerm:
        setPronPositionTypeString(t("PronOnQuestion"));
        break;
      case PronunciationPosition.onDefinition:
        setPronPositionTypeString(t("PronOnAnswer"));
        break;
    }
  }

  function handleOnClickSelectedLevelButton(
    event: React.MouseEvent<HTMLDivElement>
  ) {
    setAnchorEl(event.currentTarget);
    setIsOpenSelectedLevelMenu(true);
  }

  function handleOnClickSortButton(event: React.MouseEvent<HTMLDivElement>) {
    setAnchorEl(event.currentTarget);
    setIsOpenSortMenu(true);
  }

  function handleOnClickQuestionTypeButton(
    event: React.MouseEvent<HTMLDivElement>
  ) {
    setAnchorEl(event.currentTarget);
    setIsOpenQuestionTypeMenu(true);
  }

  function handleOnClickPronPositionButton(
    event: React.MouseEvent<HTMLDivElement>
  ) {
    setAnchorEl(event.currentTarget);
    setIsOpenPronPositionMenu(true);
  }

  function handleOnCloseSelectedLevelButton(selectedLevels: TermLevel[]) {
    setIsOpenSelectedLevelMenu(false);
    setSelectedLevelsToLocalStroage(selectedLevels);
    setSelectedLevels(selectedLevels);
  }

  function handleOnCloseSortButton(selectedOption: SortType) {
    setIsOpenSortMenu(false);
    setSortTypeToLocalStroage(selectedOption);
    setSortType(selectedOption);
  }

  function handleOnCloseQuestionTypeButton(selectedOption: QuestionType) {
    setIsOpenQuestionTypeMenu(false);
    setQuestionTypeToLocalStorage(selectedOption);
    setQuestionType(selectedOption);
  }

  function handleOnClosePronPositionButton(
    selectedOption: PronunciationPosition
  ) {
    setIsOpenPronPositionMenu(false);
    setPronPositionToLocalStorage(selectedOption);
    setPronPosition(selectedOption);
  }

  function handleOnCloseSettingMenu() {
    setIsOpenSelectedLevelMenu(false);
    setIsOpenSortMenu(false);
    setIsOpenQuestionTypeMenu(false);
    setIsOpenPronPositionMenu(false);
  }

  function handleOnClickTermCellMoreButton(
    event: React.MouseEvent<HTMLDivElement>,
    term: Term
  ) {
    setAnchorEl(event.currentTarget);
    setIsOpenTermCellMoreMenu(term);
  }

  function handleOnCloseTermCellMoreMenu() {
    setIsOpenTermCellMoreMenu(null);
  }

  function handleOnClickSelectModeButton() {
    setIsSelectionMode(!isSelectionMode);
  }

  function handleOnClickSelectAllButton() {
    updateSelectionTerms(true);
  }

  function handleOnClickCancelSelectionAllButton() {
    updateSelectionTerms(false);
  }

  function handleOnClickTerm(term: Term, isSelectionMode: boolean) {
    const termIndex = filteredTerms.findIndex((t) => t.id === term.id);
    const newTerms = [...filteredTerms];
    if (termIndex !== -1) {
      if (isSelectionMode) {
        newTerms[termIndex] = {
          ...newTerms[termIndex],
          selected: !newTerms[termIndex].selected,
        };
      } else {
        newTerms[termIndex] = {
          ...newTerms[termIndex],
          isShowDetail: !newTerms[termIndex].isShowDetail,
        };
      }
    }
    setFilteredTerms(newTerms);
  }

  function updateSelectionTerms(selection: boolean) {
    const newTerms = filteredTerms.map((term) => ({
      ...term,
      selected: selection,
    }));
    setFilteredTerms(newTerms);
  }

  function getSelectedTerms(): Term[] {
    const selectedTerms = filteredTerms.filter(
      (term) => term.selected === true
    );
    return selectedTerms;
  }

  return (
    <>
      <div className={styles.stack}>
        <motion.div animate={controls} className={styles.screen}>
          <div className={styles.topContainer}>
            <div
              className={styles.groupSelectedContainer}
              onClick={() => {
                setShowGroupSelectScreen(true);
              }}
            >
              <p className={styles.groupSelectedTitle}>
                {selectedGroupsString}
              </p>
              <img
                className={styles.groupSelectDropDownImage}
                src="iconImgAllBack.png"
              />
            </div>
          </div>
          <div className={styles.listSettingContainer}>
            <div className={styles.dropDownMenuContainer}>
              <DropDownMenu
                value={selectedLevelsString}
                handleOnClick={handleOnClickSelectedLevelButton}
              />
              <DropDownMenu
                value={sortTypeString}
                handleOnClick={handleOnClickSortButton}
              />
              <DropDownMenu
                value={questionTypeString}
                handleOnClick={handleOnClickQuestionTypeButton}
              />
              <DropDownMenu
                value={pronPositionTypeString}
                handleOnClick={handleOnClickPronPositionButton}
              />
            </div>
            <></>
          </div>
          {filteredTerms.length !== 0 && (
            <div className={styles.listFeatursContainer}>
              <SmallButton
                title={t("TermsShuffleButton")}
                style={isSelectionMode === true ? "disable" : "normal"}
                onClick={() => {
                  if (isSelectionMode !== true) {
                    setSortType(SortType.recent);
                    props.handleOnClickShuffleButton();
                  }
                }}
              />
              <SmallButton
                title={
                  isSelectionMode
                    ? t("SelectionModeOffButton")
                    : t("SelectionModeOnButton")
                }
                style="normal"
                onClick={() => handleOnClickSelectModeButton()}
              />
              {isSelectionMode === true && (
                <SmallButton
                  title={
                    selectedTermsCount === 0
                      ? t("SelectAllTerms")
                      : t("SelectCancelAllTerms", {
                          termsCount: selectedTermsCount,
                        })
                  }
                  style="normal"
                  onClick={() => {
                    selectedTermsCount === 0
                      ? handleOnClickSelectAllButton()
                      : handleOnClickCancelSelectionAllButton();
                  }}
                />
              )}
            </div>
          )}
          {isSelectionMode === true && selectedTermsCount !== 0 && (
            <div className={styles.selectionModeButtonsContainer}>
              <SmallButton
                title={t("SelectionTermsGroupChangeButton", {
                  selectedCount: getSelectedTerms().length,
                })}
                style="simple"
                onClick={() =>
                  props.handleOnClickSelectedTermsGroupChangeButton(
                    getSelectedTerms()
                  )
                }
              />
              <SmallButton
                title={t("SelectionTermsLevelChangeButton", {
                  selectedCount: getSelectedTerms().length,
                })}
                style="simple"
                onClick={(e) =>
                  props.handleOnClickSelectedTermsLevelChangeButton(
                    e,
                    getSelectedTerms()
                  )
                }
              />
              <SmallButton
                title={t("SelectionTermsDeleteButton", {
                  selectedCount: getSelectedTerms().length,
                })}
                style="simple"
                onClick={() =>
                  props.handleOnClickSelectedTermsDeleteButton(
                    getSelectedTerms()
                  )
                }
              />
            </div>
          )}
          <div className={styles.searchContainer}>
            <OnevocaSearchInput
              hint={t("SearchTermsNavTitle")}
              handleSearch={(searchText: string) => {
                if (searchText.length !== 0) {
                  setFilteredTerms(
                    getFilteredTermsWithQuery(
                      searchText,
                      getFilteredTermsWithOptions(rawTerms)
                    )
                  );
                } else {
                  setFilteredTerms(rawTerms);
                }
              }}
            />
            <div
              className={styles.termAddButtonContainer}
              onClick={() => props.handleOnClickTermAddButton()}
            >
              <AddCircle
                style={{ fontSize: 40, color: "var(--primary-color)" }}
              />
            </div>
          </div>
          <div className={styles.termsContainer}>
            <TermListGrid
              terms={filteredTerms}
              handleOnClick={handleOnClickTerm}
              handleOnClickLevelButton={props.handleOnClickLevelButton}
              handleOnClickMoreButton={(event, term) => {
                handleOnClickTermCellMoreButton(event, term);
              }}
              config={{
                style: "list",
                questionType: questionType,
                pronunciationPosition: pronPosition,
                isSelectionMode: isSelectionMode,
              }}
            />
            {rawTerms.length === 0 && props.isLoading === false && (
              <div className={styles.termsEmptyTextContainer}>
                <ListEmptyText text={t("TermListEmptyLabel")} />
              </div>
            )}
          </div>
          {isVisibleScrollToTopButton && (
            <div
              className={styles.scrollToTopButtonContainer}
              onClick={scrollToTop}
            >
              <NorthRounded className={styles.scrollToTopButton} />
            </div>
          )}
        </motion.div>
        <GroupSelectScreen
          open={showGroupSelectScreen === true}
          selectStyle="multiple"
          showAllGroup={true}
          showNoGroup={true}
          showGroupAddButton={true}
          handleOnSelectGroups={(groups: Group[]) => {
            setSelectedGroupsToLocalStroage(groups);
            setSelectedGroups(getSelectedGroupsFromLocalStorage());
          }}
          handleOnClose={() => {
            setShowGroupSelectScreen(false);
          }}
        />
        <BasicMenu
          isOpen={isOpenSelectedLevelMenu}
          anchorEl={anchorEl}
          buttons={selectedLevelButtons}
          handleOnClose={() => handleOnCloseSettingMenu()}
        />
        <BasicMenu
          isOpen={isOpenSortMenu}
          anchorEl={anchorEl}
          buttons={sortButtons}
          handleOnClose={() => handleOnCloseSettingMenu()}
        />
        <BasicMenu
          isOpen={isOpenQuestionTypeMenu}
          anchorEl={anchorEl}
          buttons={questionTypeButtons}
          handleOnClose={() => handleOnCloseSettingMenu()}
        />
        <BasicMenu
          isOpen={isOpenPronPositionMenu}
          anchorEl={anchorEl}
          buttons={pronPositionButtons}
          handleOnClose={() => handleOnCloseSettingMenu()}
        />
        <BasicMenu
          isOpen={isOpenTermCellMoreMenu !== null}
          anchorEl={anchorEl}
          buttons={getTermCellMoreButtons()}
          handleOnClose={() => handleOnCloseTermCellMoreMenu()}
        />
      </div>
    </>
  );
}
