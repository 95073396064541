import { useEffect, useState } from "react";
import PlaceHolderTextField from "../common/PlaceHolderTextField";
import styles from "./GroupAddScreen.module.css";
import { useTranslation } from "react-i18next";
import Language from "../language/Language";
import BigButton from "../common/BigButton";
import VoiceLanguageSelectScreen from "../voice/VoiceLanguageSelectScreen";
import {
  addGroup,
  mergeGroups,
  updateGroup as updateGroupFromAPI,
} from "./groupAPI";
import { Status } from "../common/Status";
import LoadingScreen from "../common/LoadingScreen";
import Group from "./Group";
import { getLanguageFromCode } from "../voice/languages";

interface GroupAddScreenProps {
  open: boolean;
  group: Group | null;
  selectedGroups: Group[] | null;
  onCreateGroup: () => void;
  onUpdateGroup: () => void;
  onClose: () => void;
}

export default function GroupAddScreen(props: GroupAddScreenProps) {
  const { t } = useTranslation();
  const [selectedGroupLanguage, setSelectedGroupLanguage] =
    useState<Language>();
  const [currentLanguageName, setCurrentLanguageName] = useState("");
  const [currentGroupName, setCurrentGroupName] = useState("");
  const [isAllowDone, setIsAllowDone] = useState(false);

  const [openLanguageSelectScreen, setOpenLanguageSelectScreen] =
    useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [groupAddStatus, setGroupAddStatus] = useState(Status.Ready);
  const [groupUpdateStatus, setGroupUpdateStatus] = useState(Status.Ready);

  useEffect(() => {
    if (props.open === true) {
      if (props.group === null) {
        setSelectedGroupLanguage(undefined);
        updateCurrentLanguageName(selectedGroupLanguage);
      } else {
        setCurrentGroupName(props.group.title);
        setSelectedGroupLanguage(
          getLanguageFromCode(props.group.language?.code ?? "")
        );
      }
    }
  }, [props.open]);

  useEffect(() => {
    setIsAllowDone(checkIsAllowDone());
    updateCurrentLanguageName(selectedGroupLanguage);
  }, [selectedGroupLanguage]);

  useEffect(() => {
    setIsAllowDone(checkIsAllowDone());
  }, [currentGroupName]);

  useEffect(() => {
    switch (groupAddStatus) {
      case Status.Ready:
        setIsLoading(false);
        break;
      case Status.Loading:
        setIsLoading(true);
        break;
      case Status.Done:
        setIsLoading(false);
        props.onCreateGroup();
        props.onClose();
        break;
    }
  }, [groupAddStatus]);

  useEffect(() => {
    switch (groupUpdateStatus) {
      case Status.Ready:
        setIsLoading(false);
        break;
      case Status.Loading:
        setIsLoading(true);
        break;
      case Status.Done:
        setIsLoading(false);
        props.onUpdateGroup();
        props.onClose();
        break;
    }
  }, [groupUpdateStatus]);

  function checkIsAllowDone(): boolean {
    if (selectedGroupLanguage === undefined) {
      return false;
    } else if (currentGroupName.length === 0 || currentGroupName.length > 50) {
      return false;
    } else {
      return true;
    }
  }

  function updateCurrentLanguageName(selectedLanguage: Language | undefined) {
    const isValidLanguage =
      selectedLanguage !== undefined && selectedLanguage !== null;
    if (isValidLanguage) {
      setCurrentLanguageName(selectedLanguage.name);
    } else {
      setCurrentLanguageName(t("SelectLanguage"));
    }
  }

  function resetData() {
    setCurrentGroupName("");
    setSelectedGroupLanguage(undefined);
    setCurrentLanguageName("");
  }

  function handleOnClickBackground() {
    resetData();
    props.onClose();
  }

  function handleOnClickContainer(e: React.MouseEvent<HTMLDivElement>) {
    e.stopPropagation();
  }

  function handleOnClickLanguage(language: Language) {
    setSelectedGroupLanguage(language);
  }

  async function handleOnClickDoneButton() {
    if (currentGroupName.length === 0) {
      return;
    }

    if (selectedGroupLanguage === undefined) {
      return;
    }

    if (props.selectedGroups !== null) {
      mergeGroup();
    } else if (props.group === null) {
      createGroup();
    } else {
      updateGroup();
    }
  }

  async function createGroup() {
    setGroupAddStatus(Status.Loading);
    try {
      await addGroup(currentGroupName, selectedGroupLanguage!.code);
      setGroupAddStatus(Status.Done);
    } catch (error) {
      if (error instanceof Error) {
        alert(error.message);
      } else {
        alert(error);
      }
      setGroupAddStatus(Status.Ready);
    }
  }

  async function updateGroup() {
    setGroupUpdateStatus(Status.Loading);
    try {
      await updateGroupFromAPI(
        props.group!.title,
        currentGroupName,
        selectedGroupLanguage!.code
      );
      setGroupUpdateStatus(Status.Done);
    } catch (error) {
      if (error instanceof Error) {
        alert(error.message);
      } else {
        alert(error);
      }
      setGroupUpdateStatus(Status.Ready);
    }
  }

  async function mergeGroup() {
    setGroupUpdateStatus(Status.Loading);
    const grouopnames = props.selectedGroups!.map((group) => group.title);
    try {
      await mergeGroups(
        grouopnames,
        currentGroupName,
        selectedGroupLanguage!.code
      );
      setGroupUpdateStatus(Status.Done);
    } catch (error) {
      if (error instanceof Error) {
        alert(error.message);
      } else {
        alert(error);
      }
      setGroupUpdateStatus(Status.Ready);
    }
  }

  if (props.open === true) {
    return (
      <>
        <div className={styles.stack}>
          <div
            className={styles.container}
            onClick={() => handleOnClickBackground()}
          >
            <div
              className={styles.groupAddContainer}
              onClick={handleOnClickContainer}
            >
              <div className={styles.inputContainer}>
                <PlaceHolderTextField
                  text={currentGroupName}
                  hint={t("GroupnameTitle")}
                  maxCount={50}
                  lineStyle="single"
                  handleTextChange={(text: string) => {
                    setCurrentGroupName(text);
                  }}
                />
              </div>
              <div className={styles.languageSelectContainer}>
                <p className={styles.languageSelectTitleText}>
                  {t("GROUP_LANGUAGE_DESCRIPTION_TEXT")}
                </p>
                <BigButton
                  title={currentLanguageName}
                  style={
                    selectedGroupLanguage !== undefined &&
                    selectedGroupLanguage !== null
                      ? "disable"
                      : "normal"
                  }
                  handleClick={() => {
                    setOpenLanguageSelectScreen(true);
                  }}
                />
              </div>
              {isAllowDone === true && (
                <div className={styles.bottomButtonContainer}>
                  <BigButton
                    title={t("Done")}
                    style="special"
                    handleClick={() => {
                      handleOnClickDoneButton();
                    }}
                  />
                </div>
              )}
            </div>
          </div>
          <VoiceLanguageSelectScreen
            open={openLanguageSelectScreen === true}
            handleOnClickLanguage={handleOnClickLanguage}
            handleOnClose={() => {
              setOpenLanguageSelectScreen(false);
            }}
          />
          {isLoading === true ? <LoadingScreen /> : null}
        </div>
      </>
    );
  } else {
    return null;
  }
}
