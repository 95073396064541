export function formatNumberWithCommas(value: number | string): string {
  let numberValue: number;

  if (typeof value === "string") {
    numberValue = parseFloat(value.replace(/,/g, ""));
    if (isNaN(numberValue)) {
      console.error(`Invalid input: ${value} is not a valid number.`);
      return "";
    }
  } else {
    numberValue = value;
  }

  const formatter = new Intl.NumberFormat("en-US");
  return formatter.format(numberValue);
}

export function sanitizeString(input: string): string {
  let sanitized = input.replace(/\(.*?\)/g, "");
  sanitized = sanitized.replace(/\{.*?\}/g, "");
  sanitized = sanitized.replace(/\[.*?\]/g, "");
  sanitized = sanitized.replace(/[^\p{L}\p{N}]/gu, ""); // `u` 플래그로 유니코드 지원
  return sanitized;
}

export function sanitizeAndSplitString(input: string): string[] {
  let sanitized = input.replace(/\(.*?\)/g, "");
  sanitized = sanitized.replace(/\{.*?\}/g, "");
  sanitized = sanitized.replace(/\[.*?\]/g, "");

  const parts = sanitized.split(",");
  const sanitizedParts = parts.map(
    (part) => part.replace(/[^\p{L}\p{N}]/gu, "") // `u` 플래그로 유니코드 지원
  );

  return sanitizedParts;
}

export function normalizeString(str: string): string {
  return str
    .toLowerCase() // 소문자로 변환
    .replace(/\s+/g, ""); // 모든 공백 제거
  // .replace(/[^\w]/g, ""); // 특수문자 제거
}
