import { getUid } from "../auth/AuthManager";
import OnevocaServerError from "../common/OnevocaServerError";
import getApiInstance from "../common/api";
import Group from "./Group";
import { auth } from "../../configurations/firebaseConfig";
import { sortedByNumbers } from "./groups";

export async function fetchGroups(
  withReturnAllGroup: boolean,
  withReturnNoSelectGroup: boolean
): Promise<Group[]> {
  try {
    const uid = await getUid();
    const response = await getApiInstance(undefined).get(
      "/group/get_list.php",
      {
        params: {
          uid: uid,
          is_all: withReturnAllGroup ? 1 : 0,
          is_no_group: withReturnNoSelectGroup ? 1 : 0,
        },
      }
    );
    const error = response.data.error;
    if (error !== undefined) {
      return Promise.reject(new OnevocaServerError(error));
    } else {
      const groupsData = response.data;
      let newGroups: Group[] = [];
      let realGroups: Group[] = [];
      groupsData.map((groupData: any) => {
        const group = Group.fromJson(groupData);
        if (group.title === "all") {
          newGroups.push(group);
        } else if (group.title === "no_group") {
          newGroups.push(group);
        } else {
          realGroups.push(group);
        }
      });
      newGroups = [...newGroups, ...sortedByNumbers(realGroups)];
      return newGroups;
    }
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function addGroup(
  groupname: string,
  languageCode: string
): Promise<void> {
  try {
    const uid = await getUid();
    const parameters = {
      uid: uid,
      n_group: groupname,
      lang: languageCode,
    };
    const response = await getApiInstance(undefined).post(
      "/group/add.php",
      parameters
    );
    const error = response.data.error;
    if (error !== undefined) {
      return Promise.reject(new OnevocaServerError(error));
    } else {
      return;
    }
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function updateGroup(
  beforeTitle: string,
  newTitle: string,
  newLanguage: string
): Promise<void> {
  try {
    const uid = await getUid();
    const parameters = {
      uid: uid,
      beforeTitle: beforeTitle,
      newTitle: newTitle,
      newLanguage: newLanguage,
    };

    const response = await getApiInstance(undefined).post(
      "/group/updateGroup.php",
      parameters
    );
    const error = response.data.error;
    if (error !== undefined) {
      return Promise.reject(new OnevocaServerError(error));
    } else {
      return;
    }
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function mergeGroups(
  groups: string[],
  groupname: string,
  language: string
): Promise<void> {
  try {
    const uid = await getUid();
    const parameters: { [key: string]: any } = {};
    parameters["uid"] = uid;
    parameters["newgroup"] = groupname;
    parameters["lang"] = language;

    const groupParameters: { [key: string]: any } = {};
    groups.forEach((title, i) => {
      groupParameters[`${i}`] = title;
    });
    parameters["groups"] = groupParameters;

    const response = await getApiInstance(undefined).post(
      "/group/merge.php",
      parameters
    );

    const error = response.data.error;
    if (error !== undefined) {
      return Promise.reject(new OnevocaServerError(error));
    } else {
      return;
    }
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function deleteGroups(groups: string[]): Promise<void> {
  try {
    const uid = await getUid();
    const parameters: { [key: string]: any } = {};
    parameters["uid"] = uid;
    const groupParameters: { [key: string]: any } = {};

    groups.forEach((title, i) => {
      groupParameters[`${i}`] = title;
    });

    parameters["groups"] = groupParameters;

    const response = await getApiInstance(undefined).post(
      "/group/remove_array.php",
      parameters
    );

    const error = response.data.error;
    if (error !== undefined) {
      return Promise.reject(new OnevocaServerError(error));
    } else {
      return;
    }
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function deleteGroup(title: string): Promise<void> {
  try {
    const uid = await getUid();
    const response = await getApiInstance(undefined).get("/group/remove.php", {
      params: {
        uid: uid,
        gn: title,
      },
    });
    const error = response.data.error;
    if (error !== undefined) {
      return Promise.reject(new OnevocaServerError(error));
    } else {
      return;
    }
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function fetchGroupLanguageCode(group: string): Promise<string> {
  const parameters = {
    uid: auth.currentUser?.providerData[0].uid,
    group: group,
  };

  try {
    const response = await getApiInstance().post(
      "/group/get_lang.php",
      parameters
    );
    const error = response.data.error;
    if (error !== undefined) {
      return Promise.reject(new OnevocaServerError(error));
    }

    const language = response.data.lang;
    if (language === undefined) {
      return Promise.reject(new Error("Not found language of group"));
    }
    return language;
  } catch (error) {
    return Promise.reject(error);
  }
}
