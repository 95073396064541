import i18n from "i18next";
import Group from "./Group";

export function getExistGroup(groups: Group[], fromGroups: Group[]): Group[] {
  return groups.filter((group) =>
    fromGroups.some((fromGroup) => fromGroup.title === group.title)
  );
}

export function convertGroupString(group: string): string {
  if (group === "all") {
    return i18n.t("AllGroups");
  } else if (group === "no_group") {
    return i18n.t("ThereIsNoGroup");
  } else {
    return group;
  }
}

export function makeSelectedGroupsString(selectedGroups: Group[]): string {
  if (selectedGroups.length === 0) {
    return i18n.t("AllGroups");
  } else if (selectedGroups.length === 1) {
    const selectedGroup = selectedGroups[0];
    if (selectedGroup.title === "") {
      return i18n.t("ThereIsNoGroup");
    } else {
      return selectedGroup.title;
    }
  } else {
    let value = "";
    selectedGroups.forEach((group, index) => {
      if (group.title === "" || group.title === "no_group") {
        if (index === 0) {
          value += i18n.t("ThereIsNoGroup");
        } else {
          value += `, ${i18n.t("ThereIsNoGroup")}`;
        }
      } else {
        if (index === 0) {
          value += group.title;
        } else {
          value += `, ${group.title}`;
        }
      }
    });
    return value;
  }
}

export function sortedByNumbers(groups: Group[]): Group[] {
  return groups.sort((lhs, rhs) => {
    // 숫자 추출
    const lhsNumber =
      extractTrailingNumber(lhs.title) ?? Number.MAX_SAFE_INTEGER;
    const rhsNumber =
      extractTrailingNumber(rhs.title) ?? Number.MAX_SAFE_INTEGER;

    // 숫자 없는 부분 추출
    const lhsNonNumeric = removeTrailingNumber(lhs.title).toLowerCase();
    const rhsNonNumeric = removeTrailingNumber(rhs.title).toLowerCase();

    // 숫자 없는 부분 비교 먼저 수행, 동일하면 숫자 비교
    if (lhsNonNumeric === rhsNonNumeric) {
      return lhsNumber - rhsNumber;
    } else {
      return lhsNonNumeric.localeCompare(rhsNonNumeric);
    }
  });
}

function extractTrailingNumber(text: string): number | null {
  const match = text.match(/(\d+)$/); // 끝에 있는 숫자를 찾는 정규식
  return match ? parseInt(match[1], 10) : null; // 숫자를 정수로 변환
}

function removeTrailingNumber(text: string): string {
  return text.replace(/(\d+)$/, ""); // 끝에 있는 숫자를 제거
}
