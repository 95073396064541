import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./TermAddScreen.module.css";
import Group from "../groups/Group";
import BigButton from "../common/BigButton";
import TermAddField from "./TermAddField";
import Term, { TermLevelUpdate } from "./Term";
import { TermLevel } from "./TermLevel";
import GroupSelectScreen from "../groups/GroupSelectScreen";
import OnevocaDialog from "../common/OnevocaDialog";
import { insertTerms } from "./termAPI";
import { Status } from "../common/Status";
import LoadingScreen from "../common/LoadingScreen";
import OnevocaServerError, {
  errorNeedMoreSlot,
} from "../common/OnevocaServerError";
import {
  getSavedTermsFromIndexedDB,
  setSavedTermsToIndexedDB,
} from "../settings/termListSettings";
import { useNavigate } from "react-router-dom";

interface TermAddScreenProps {
  open: boolean;
  selectedGroup?: Group;
  onSaved: () => void;
  onClose: () => void;
}

export default function TermAddScreen(props: TermAddScreenProps) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState<Group>();
  const [terms, setTerms] = useState<Term[]>([]);
  const [showGroupSelectScreen, setShowGroupSelectScreen] = useState(false);
  const [showLoadSavedTermsDialog, setShowLoadSavedTermsDialog] =
    useState(false);
  const [termsInsertStatus, setTermsInsertStatus] = useState(Status.Ready);
  const [showSlotNeedMessage, setShowSlotNeedMessage] = useState(false);

  const inputRefs = useRef<(HTMLTextAreaElement | null)[]>([]);
  const firstInputRefs = useRef<(HTMLTextAreaElement | null)[]>([]);

  useEffect(() => {
    if (props.open === true) {
      getSavedTerms();
    }
  }, [props.open]);

  useEffect(() => {
    selectedGroup !== undefined && updateGroupOfTerms(selectedGroup);
  }, [selectedGroup]);

  useEffect(() => {
    if (terms !== null && terms.length !== 0) {
      try {
        setSavedTermsToIndexedDB(terms);
      } catch (error) {
        alert(error);
      }
    }
  }, [terms]);

  useEffect(() => {
    switch (termsInsertStatus) {
      case Status.Ready:
        setIsLoading(false);
        break;
      case Status.Loading:
        setIsLoading(true);
        break;
      case Status.Done:
        setIsLoading(false);
        finishSaveTerms();
        break;
    }
  }, [termsInsertStatus]);

  async function getSavedTerms(): Promise<Term[]> {
    const savedTerms = await getSavedTermsFromIndexedDB();
    if (savedTerms.length === 0) {
      insertTerm();
    } else {
      setShowLoadSavedTermsDialog(true);
    }

    if (props.selectedGroup !== undefined) {
      setSelectedGroup(props.selectedGroup);
    }
    return savedTerms;
  }

  function finishSaveTerms() {
    setSavedTermsToIndexedDB([]);
    props.onSaved();
    closeScreen();
  }

  async function loadSavedTerms() {
    try {
      const savedTerms = await getSavedTermsFromIndexedDB();
      const termsCopy = [...savedTerms];
      setTerms(termsCopy);
    } catch (error) {
      alert(error);
    }
  }

  function updateGroupOfTerms(group: Group) {
    const updatedTerms = terms.map((term) => ({ ...term, group: group.title }));
    setTerms(updatedTerms);
  }

  function updateTermWord(value: string, index: number) {
    const term = terms[index];
    term.term = value;
    const newTerms = [...terms];
    newTerms[index] = term;
    setTerms(newTerms);
  }

  function moveToSlotPurchase() {
    navigate("/slot-purchase");
  }

  function insertTerm() {
    const group = selectedGroup === undefined ? "" : selectedGroup.title;
    const term = new Term(
      terms.length.toString(),
      "",
      "",
      "",
      "",
      group,
      TermLevel.difficult,
      0,
      0,
      false,
      TermLevelUpdate.none
    );
    const newTerms = [...terms];
    newTerms.push(term);
    setTerms(newTerms);

    setTimeout(() => {
      const lastIndex = terms.length;
      const newInput = firstInputRefs.current[lastIndex];
      newInput && newInput.focus();
    }, 0);
  }

  function updateTermDefinition(value: string, index: number) {
    const term = terms[index];
    term.definition = value;
    const newTerms = [...terms];
    newTerms[index] = term;
    setTerms(newTerms);
  }

  function updateTermMemo(value: string, index: number) {
    const term = terms[index];
    term.memo = value;
    const newTerms = [...terms];
    newTerms[index] = term;
    setTerms(newTerms);
  }

  function updateTermPronunciation(value: string, index: number) {
    const term = terms[index];
    term.pronunciation = value;
    const newTerms = [...terms];
    newTerms[index] = term;
    setTerms(newTerms);
  }

  function removeTerm(index: number) {
    const newTerms = [...terms];
    if (index !== -1) {
      newTerms.splice(index, 1);
    }
    setTerms(newTerms);
  }

  function handleOnClickBackground(e: React.MouseEvent<HTMLDivElement>) {
    e.stopPropagation();
  }

  function handleOnClickCancelButton() {
    closeScreen();
  }

  async function handleOnClickSaveButton() {
    setTermsInsertStatus(Status.Loading);
    try {
      const validTerms = getValidTerms();
      await insertTerms(validTerms, selectedGroup!.title);
      setTermsInsertStatus(Status.Done);
    } catch (error) {
      if (error instanceof OnevocaServerError) {
        if (error.name === errorNeedMoreSlot) {
          setShowSlotNeedMessage(true);
        } else {
          alert(error.message);
        }
      } else if (error instanceof Error) {
        alert(error.message);
      } else {
        alert(error);
      }
      setTermsInsertStatus(Status.Ready);
    }
  }

  function closeScreen() {
    setTerms([]);
    setSelectedGroup(undefined);
    props.onClose();
  }

  function getValidTerms(): Term[] {
    const validTerms = terms.filter(
      (term) => term.term.length !== 0 && term.definition.length !== 0
    );

    if (selectedGroup === undefined) {
      throw new Error(t("SelectGroupMessage"));
    }

    if (validTerms.length === 0) {
      throw new Error(t("MoreThanOneTermMessage"));
    }

    return validTerms;
  }

  function handleKeyDown(
    event: React.KeyboardEvent<HTMLTextAreaElement>,
    index: number
  ) {
    if (event.key === "Tab" && index === terms.length - 1) {
      event.preventDefault();
      insertTerm();
    }
  }

  if (props.open === true) {
    return (
      <>
        <div className={styles.stack}>
          <div className={styles.container} onClick={handleOnClickBackground}>
            <div className={styles.termAddContainer}>
              <div className={styles.groupSelectContainer}>
                {selectedGroup === undefined && (
                  <p className={styles.groupSelectTitleText}>
                    {t("GroupSelectToTermsMessage")}
                  </p>
                )}
                <BigButton
                  title={
                    selectedGroup === undefined
                      ? t("SelectGroupButton")
                      : selectedGroup.title
                  }
                  style={selectedGroup === undefined ? "normal" : "disable"}
                  handleClick={() => {
                    setShowGroupSelectScreen(true);
                  }}
                />
              </div>
              <div className={styles.termAddFieldsContainer}>
                {terms.length > 0 &&
                  terms.map((term, index) => (
                    <TermAddField
                      key={index}
                      page={index + 1}
                      term={term}
                      showRemoveButton={index !== 0}
                      onClickRemoveButton={() => removeTerm(index)}
                      onChangeTerm={(word) => updateTermWord(word, index)}
                      onChangeDefinition={(definition) =>
                        updateTermDefinition(definition, index)
                      }
                      onChangeMemo={(memo) => updateTermMemo(memo, index)}
                      onChangePronunciation={(pronunciation) =>
                        updateTermPronunciation(pronunciation, index)
                      }
                      onKeyDown={(e) => handleKeyDown(e, index)}
                      firstInputRef={(el) =>
                        (firstInputRefs.current[index] = el)
                      }
                      inputRef={(el) => (inputRefs.current[index] = el)}
                    />
                  ))}
                <p className={styles.termAddFieldTabKeyTipText}>
                  {t("TermsMultipleAddTipMessage")}
                </p>
              </div>
              <div className={styles.bottomButtonContainer}>
                <BigButton
                  title={t("Cancel")}
                  style="disable"
                  handleClick={() => handleOnClickCancelButton()}
                />
                <BigButton
                  title={t("Save")}
                  style={selectedGroup === undefined ? "disable" : "normal"}
                  handleClick={() => handleOnClickSaveButton()}
                />
              </div>
            </div>
          </div>
          <GroupSelectScreen
            open={showGroupSelectScreen === true}
            selectStyle="single"
            showAllGroup={false}
            showNoGroup={false}
            showGroupAddButton={true}
            handleOnSelectGroups={(groups: Group[]) => {
              if (groups.length !== 0) {
                const group = groups[0];
                setSelectedGroup(group);
              }
            }}
            handleOnClose={() => {
              setShowGroupSelectScreen(false);
            }}
          />
          <OnevocaDialog
            open={showLoadSavedTermsDialog === true}
            title={null}
            message={t("SavedTermsExistMessage")}
            buttons={[
              {
                title: t("ContinueRegisterTerms"),
                style: "solid",
                onClick: () => {
                  setShowLoadSavedTermsDialog(false);
                  loadSavedTerms();
                },
              },
              {
                title: t("NewRegisterTerms"),
                style: "normal",
                onClick: () => {
                  setShowLoadSavedTermsDialog(false);
                  setSavedTermsToIndexedDB([]);
                  insertTerm();
                },
              },
            ]}
          />
          <OnevocaDialog
            open={showSlotNeedMessage === true}
            title={t("Notice")}
            message={t("SlotNeedDialogMessage")}
            buttons={[
              {
                title: t("SlotAddButton"),
                style: "solid",
                onClick: () => {
                  setShowSlotNeedMessage(false);
                  moveToSlotPurchase();
                },
              },
              {
                title: t("Cancel"),
                style: "normal",
                onClick: () => {
                  setShowSlotNeedMessage(false);
                },
              },
            ]}
          />
          {isLoading === true ? <LoadingScreen /> : null}
        </div>
      </>
    );
  } else {
    return null;
  }
}
