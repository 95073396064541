import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
import { PronunciationPosition } from "../settings/PronunciationPosition";
import { QuestionType } from "../settings/QuestionType";
import Term, { getGroupString } from "./Term";
import styles from "./TermCell.module.css";
import TermLevelButton from "./TermLevelButton";
import SpeakButton from "../common/SpeakButton";
import { useState } from "react";
import { fetchGroupLanguageCode } from "../groups/groupAPI";
import { getVoiceFile, makeBase64String } from "../voice/voice";
import AudioPlayer from "react-h5-audio-player";

interface TermCellProps {
  term: Term;
  handleOnClick: (term: Term, isSelectionMode: boolean) => void;
  handleOnClickLevelButton: (term: Term) => void;
  handleOnClickMoreButton: (
    event: React.MouseEvent<HTMLDivElement>,
    term: Term
  ) => void;
  config: {
    questionType: QuestionType;
    pronunciationPosition: PronunciationPosition;
    isSelectionMode: boolean;
    isShowDetailMode?: boolean;
    hideMoreButton?: boolean;
  };
}

export default function TermCell(props: TermCellProps) {
  const [isPlaying, setIsPlaying] = useState(false);
  const [audioSrc, setAudioSrc] = useState<string>();

  const firstText =
    props.config.questionType === QuestionType.termFirst ||
    props.config.questionType === QuestionType.showAll
      ? props.term.term
      : props.term.definition;
  const secondText =
    props.config.questionType === QuestionType.termFirst
      ? props.term.definition
      : props.term.term;

  const shouldShowMemo = () => {
    if (
      props.term.isShowDetail ||
      props.config.isShowDetailMode === true ||
      props.config.questionType === QuestionType.showAll
    ) {
      return props.term.memo.length !== 0;
    }
  };

  const shouldShowPronunciation = () => {
    if (
      props.term.isShowDetail ||
      props.config.isShowDetailMode === true ||
      props.config.questionType === QuestionType.showAll
    ) {
      return props.term.pronunciation.length !== 0;
    }
    if (
      props.config.questionType === QuestionType.termFirst &&
      props.config.pronunciationPosition === PronunciationPosition.onTerm
    ) {
      return props.term.pronunciation.length !== 0;
    }
    if (
      props.config.questionType === QuestionType.termFirst &&
      props.config.pronunciationPosition === PronunciationPosition.onDefinition
    ) {
      return false;
    }
    if (
      props.config.questionType === QuestionType.definitionFirst &&
      props.config.pronunciationPosition === PronunciationPosition.onTerm
    ) {
      return false;
    }
    if (
      props.config.questionType === QuestionType.definitionFirst &&
      props.config.pronunciationPosition === PronunciationPosition.onDefinition
    ) {
      return props.term.pronunciation.length !== 0;
    }
    return false;
  };

  function handleOnClickCell(e: React.MouseEvent<HTMLDivElement>) {
    e.stopPropagation();
    props.handleOnClick(props.term, props.config.isSelectionMode);
  }

  function handleOnClickLevelButton(e: React.MouseEvent<HTMLDivElement>) {
    e.stopPropagation();
    props.handleOnClickLevelButton(props.term);
  }

  function handleOnClickMoreButton(e: React.MouseEvent<HTMLDivElement>) {
    e.stopPropagation();
    props.handleOnClickMoreButton(e, props.term);
  }

  function handleOnClickSpeakButton(e: React.MouseEvent<HTMLDivElement>) {
    e.stopPropagation();
    speak();
  }

  async function speak() {
    const termToSpeak = firstText;
    const groupToSpeak =
      props.config.questionType === QuestionType.termFirst ||
      QuestionType.showAll
        ? props.term.group
        : "";

    setIsPlaying(true);
    try {
      const languageCode =
        groupToSpeak.length !== 0
          ? await fetchGroupLanguageCode(groupToSpeak)
          : "";
      const audio = await getVoiceFile(termToSpeak, languageCode);
      setAudioSrc(makeBase64String(audio));
    } catch (error) {
      if (error instanceof Error) {
        alert(error.message);
      } else {
        alert(error);
      }
    }
  }

  return (
    <>
      <div className={styles.container} onClick={handleOnClickCell}>
        {props.config.isSelectionMode && (
          <div className={styles.selectionContainer}>
            {props.term.selected ? (
              <CheckBox
                style={{ fontSize: 24, color: "var(--primary-color)" }}
              />
            ) : (
              <CheckBoxOutlineBlank
                style={{ fontSize: 24, color: "var(--light-gray-color)" }}
              />
            )}
          </div>
        )}
        <div className={styles.cardContainer}>
          <div className={styles.topContainer}>
            <TermLevelButton
              currentLevel={props.term.level}
              handleOnClick={handleOnClickLevelButton}
            />
            {props.config.hideMoreButton !== true && (
              <div
                className={styles.moreButtonContainer}
                onClick={handleOnClickMoreButton}
              >
                <img
                  className={styles.moreButtonImage}
                  src="iconImgAllMore.png"
                ></img>
              </div>
            )}
          </div>
          <div className={styles.termContainer}>
            <p className={styles.termText}>{firstText}</p>
            {props.term.isShowDetail === true ||
            props.config.isShowDetailMode === true ||
            props.config.questionType === QuestionType.showAll ? (
              <p className={styles.definitionText}>{secondText}</p>
            ) : null}
            {shouldShowMemo() && (
              <p className={styles.memoText}>{props.term.memo}</p>
            )}
            {shouldShowPronunciation() && (
              <p className={styles.pronunciationText}>
                {props.term.pronunciation}
              </p>
            )}
          </div>
          <div className={styles.bottomContainer}>
            <p className={styles.groupText}>
              {getGroupString(props.term.group)}
            </p>
            <SpeakButton
              fontSize="small"
              isPlaying={isPlaying}
              onClick={handleOnClickSpeakButton}
            />
          </div>
        </div>
        {audioSrc && (
          <AudioPlayer
            autoPlay={true}
            src={audioSrc}
            style={{ display: "none" }}
            onEnded={() => {
              setIsPlaying(false);
              setAudioSrc(undefined);
            }}
          />
        )}
      </div>
    </>
  );
}
