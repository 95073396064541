import { TermLevel } from "../term/TermLevel";
import { PronunciationPosition } from "./PronunciationPosition";
import { QuestionType } from "./QuestionType";
import { SortType } from "./SortType";
import Term from "../term/Term";
import Group from "../groups/Group";

const selectedGroupsKey = "TermListSelectedGroups";
const selectedLevelsKey = "TermListSelectedLevels";
const sortTypeKey = "TermListSortType";
const recentValue = "recent";
const oldValue = "old";
const abcValue = "abc";
const questionTypeKey = "TermListQuestionType";
const termFirstValue = "termFirst";
const defiFirstValue = "defiFirst";
const showAllValue = "showALl";
const pronPositionKey = "TermListPronPosition";
const onTermValue = "onTerm";
const onDefiValue = "onDefi";

const DB_NAME = "termsDatabase";
const DB_VERSION = 1;
const STORE_NAME = "termsStore";

export function initializeTermListSettings() {
  localStorage.removeItem(selectedGroupsKey);
  localStorage.removeItem(selectedLevelsKey);
  localStorage.removeItem(sortTypeKey);
  localStorage.removeItem(questionTypeKey);
  localStorage.removeItem(pronPositionKey);
  deleteTermsDatabase();
}

export function getSelectedGroupsFromLocalStorage(): Group[] {
  const storedGroups = localStorage.getItem(selectedGroupsKey);
  if (storedGroups === null || storedGroups === undefined) {
    return [];
  } else {
    return JSON.parse(storedGroups);
  }
}

export function setSelectedGroupsToLocalStroage(selectedGroups: Group[]) {
  if (selectedGroups.length === 1 && selectedGroups[0].title === "all") {
    localStorage.setItem(selectedGroupsKey, JSON.stringify([]));
  } else if (
    selectedGroups.length === 1 &&
    selectedGroups[0].title === "no_group"
  ) {
    const group = new Group("", 0, "", 0);
    localStorage.setItem(selectedGroupsKey, JSON.stringify([group]));
  } else {
    localStorage.setItem(selectedGroupsKey, JSON.stringify(selectedGroups));
  }
}

export function getSelectedLevelFromLocalStroage(): TermLevel[] {
  const storedLevels = localStorage.getItem(selectedLevelsKey);
  if (storedLevels === null || storedLevels === undefined) {
    localStorage.setItem(
      selectedLevelsKey,
      JSON.stringify([
        TermLevel.difficult,
        TermLevel.familiar,
        TermLevel.perfect,
      ])
    );
    return [TermLevel.difficult, TermLevel.familiar, TermLevel.perfect];
  } else {
    return JSON.parse(storedLevels);
  }
}

export function setSelectedLevelsToLocalStroage(selectedLevels: TermLevel[]) {
  localStorage.setItem(selectedLevelsKey, JSON.stringify(selectedLevels));
}

export function getSortTypeFromLocalStorage(): SortType {
  if (localStorage.getItem(sortTypeKey) === recentValue) {
    return SortType.recent;
  } else if (localStorage.getItem(sortTypeKey) === oldValue) {
    return SortType.old;
  } else if (localStorage.getItem(sortTypeKey) === abcValue) {
    return SortType.abc;
  } else {
    localStorage.setItem(sortTypeKey, recentValue);
    return SortType.recent;
  }
}

export function setSortTypeToLocalStroage(type: SortType) {
  switch (type) {
    case SortType.recent:
      localStorage.setItem(sortTypeKey, recentValue);
      break;
    case SortType.old:
      localStorage.setItem(sortTypeKey, oldValue);
      break;
    case SortType.abc:
      localStorage.setItem(sortTypeKey, abcValue);
      break;
  }
}

export function getQuestionTypeFromLocalStorage(): QuestionType {
  if (localStorage.getItem(questionTypeKey) === termFirstValue) {
    return QuestionType.termFirst;
  } else if (localStorage.getItem(questionTypeKey) === defiFirstValue) {
    return QuestionType.definitionFirst;
  } else if (localStorage.getItem(questionTypeKey) === showAllValue) {
    return QuestionType.showAll;
  } else {
    localStorage.setItem(questionTypeKey, termFirstValue);
    return QuestionType.termFirst;
  }
}

export function setQuestionTypeToLocalStorage(type: QuestionType) {
  switch (type) {
    case QuestionType.termFirst:
      localStorage.setItem(questionTypeKey, termFirstValue);
      break;
    case QuestionType.definitionFirst:
      localStorage.setItem(questionTypeKey, defiFirstValue);
      break;
    case QuestionType.showAll:
      localStorage.setItem(questionTypeKey, showAllValue);
      break;
  }
}

export function getPronPositioFromLocalStorage(): PronunciationPosition {
  if (localStorage.getItem(pronPositionKey) === onTermValue) {
    return PronunciationPosition.onTerm;
  } else if (localStorage.getItem(pronPositionKey) === onDefiValue) {
    return PronunciationPosition.onDefinition;
  } else {
    localStorage.setItem(pronPositionKey, onTermValue);
    return PronunciationPosition.onTerm;
  }
}

export function setPronPositionToLocalStorage(
  pronPosition: PronunciationPosition
) {
  switch (pronPosition) {
    case PronunciationPosition.onTerm:
      localStorage.setItem(pronPositionKey, onTermValue);
      break;
    case PronunciationPosition.onDefinition:
      localStorage.setItem(pronPositionKey, onDefiValue);
      break;
  }
}

export async function getSavedTermsFromIndexedDB(): Promise<Term[]> {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(DB_NAME, DB_VERSION);

    request.onupgradeneeded = function () {
      const db = request.result;
      if (!db.objectStoreNames.contains(STORE_NAME)) {
        db.createObjectStore(STORE_NAME, { autoIncrement: true });
      }
    };

    request.onsuccess = function () {
      const db = request.result;
      if (!db.objectStoreNames.contains(STORE_NAME)) {
        reject(new Error(`Object store ${STORE_NAME} not found`));
        return;
      }

      const transaction = db.transaction(STORE_NAME, "readonly");
      const store = transaction.objectStore(STORE_NAME);

      const getAllRequest = store.getAll();

      getAllRequest.onsuccess = function () {
        resolve(getAllRequest.result as Term[]);
      };
      getAllRequest.onerror = function () {
        reject(getAllRequest.error);
      };
    };

    request.onerror = function () {
      reject(request.error);
    };
  });
}

export async function setSavedTermsToIndexedDB(terms: Term[]): Promise<void> {
  return new Promise((resolve, reject) => {
    const filteredTerms = terms.filter((term) => {
      return term.term !== "" && term.definition !== "";
    });

    const request = indexedDB.open(DB_NAME, DB_VERSION);

    request.onupgradeneeded = function () {
      const db = request.result;
      if (!db.objectStoreNames.contains(STORE_NAME)) {
        db.createObjectStore(STORE_NAME, { autoIncrement: true });
      }
    };

    request.onsuccess = function () {
      const db = request.result;
      const transaction = db.transaction(STORE_NAME, "readwrite");
      const store = transaction.objectStore(STORE_NAME);

      // 기존 데이터를 삭제합니다.
      const clearRequest = store.clear();

      clearRequest.onsuccess = function () {
        // 모든 항목을 추가합니다.
        filteredTerms.forEach((term) => {
          store.add(term); // 데이터를 추가합니다.
        });

        transaction.oncomplete = function () {
          resolve();
        };
      };

      clearRequest.onerror = function () {
        reject(clearRequest.error);
      };
    };

    request.onerror = function () {
      reject(request.error);
    };
  });
}

export async function deleteTermsDatabase(): Promise<void> {
  return new Promise((resolve, reject) => {
    const deleteRequest = indexedDB.deleteDatabase(DB_NAME);
    deleteRequest.onsuccess = function () {
      resolve();
    };
    deleteRequest.onerror = function () {
      reject(deleteRequest.error);
    };
  });
}
