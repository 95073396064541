import React, { useEffect, useState } from "react";
import OnevocaSearchInput from "../common/OnevocaSearchInput";
import styles from "./GroupSelectScreen.module.css";
import { useTranslation } from "react-i18next";
import {
  deleteGroups as deleteGroupsFromAPI,
  fetchGroups as fetchGroupsFromAPI,
} from "./groupAPI";
import Group from "./Group";
import GroupSelectCell from "./GroupSelectCell";
import { Status } from "../common/Status";
import LoadingScreen from "../common/LoadingScreen";
import { cloneDeep } from "lodash";
import BigButton from "../common/BigButton";
import { Snackbar } from "@mui/material";
import { AddRounded, EditRounded } from "@mui/icons-material";
import GroupAddScreen from "./GroupAddScreen";
import OnevocaDialog from "../common/OnevocaDialog";
import BasicMenu, { BasicMenuButton } from "../common/BasicMenu";
import { deleteGroup as deleteGroupFromAPI } from "./groupAPI";
import {
  getSelectedGroupsFromLocalStorage,
  setSelectedGroupsToLocalStroage,
} from "../settings/termListSettings";
import { getExistGroup, sortedByNumbers } from "./groups";
import ListEmptyText from "../common/ListEmptyText";
import { setgroups } from "process";

interface GroupSelectScreenProps {
  open: boolean;
  showAllGroup: boolean;
  showNoGroup: boolean;
  selectStyle: "single" | "multiple";
  showGroupAddButton: boolean;
  handleOnSelectGroups: (groups: Group[]) => void;
  handleOnClose: () => void;
}

export default function GroupSelectScreen(props: GroupSelectScreenProps) {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [isLoading, setIsLoading] = useState(false);
  const [groups, setGroups] = useState<Group[]>([]);
  const [groupsToShow, setGroupsToShow] = useState<Group[]>([]);
  const [groupFetchStatus, setGroupFetchStatus] = useState(Status.Ready);
  const [groupDeleteStatus, setGroupDeleteStatus] = useState(Status.Ready);
  const [selectedCount, setSelectedCount] = useState(0);
  const [openGroupAddScreen, setOpenGroupAddScreen] = useState(false);
  const [toastMessage, setToastMessage] = useState<string | null>(null);
  const [searchDefaultText, setSearchDefaultText] = useState<string>();

  const [groupEditSelectedItem, setGroupEditSelectedItem] =
    useState<Group | null>(null);
  const [showGroupEditItem, setShowGroupEditItem] = useState<Group | null>(
    null
  );
  const [showGroupDeleteItem, setShowGroupDeleteItem] = useState<Group | null>(
    null
  );
  const [
    showingSelectedGroupDeleteDialog,
    setShowingSelectedGroupDeleteDialog,
  ] = useState(false);
  const [showingGroupMerge, setShowingGroupMerge] = useState(false);
  const [selectedGroups, setSelectedGroups] = useState<Group[] | null>(null);

  const [showingAddButton, setShowingAddButton] = useState(false);
  const [showingEditButton, setShowingEditButton] = useState(false);
  const [showingSelectedEditMenu, setShowingSelectedEditMenu] = useState(false);

  const selectedEditMenu: BasicMenuButton[] = [
    {
      title: t("SelectedGroupMergeButton", { selectedCount }),
      handleOnClose: () => handleOnClickSelectedGroupMergeButton(),
    },
    {
      title: t("SelectedGroupDeleteButton", { selectedCount }),
      handleOnClose: () => handleOnClickSelectedGroupDeleteButton(),
    },
  ];

  const moreButtons: BasicMenuButton[] = [
    {
      title: t("Edit"),
      handleOnClose: () => handleOnCloseEditButton(),
    },
    {
      title: t("Delete"),
      handleOnClose: () => handleOnCloseDeleteButton(),
    },
  ];

  useEffect(() => {}, [showingAddButton]);

  useEffect(() => {}, [showingEditButton]);

  useEffect(() => {
    if (props.open === true) {
      fetchGroups();
    }
  }, [props.open]);

  useEffect(() => {
    switch (groupFetchStatus) {
      case Status.Ready:
        setIsLoading(false);
        break;
      case Status.Loading:
        setIsLoading(true);
        break;
      case Status.Done:
        setIsLoading(false);
        setSelectedGroupsToLocalStroage(
          getExistGroup(getSelectedGroupsFromLocalStorage(), groups)
        );
        break;
    }
  }, [groupFetchStatus]);

  useEffect(() => {
    switch (groupDeleteStatus) {
      case Status.Ready:
        setIsLoading(false);
        break;
      case Status.Loading:
        setIsLoading(true);
        break;
      case Status.Done:
        setIsLoading(false);
        break;
    }
  }, [groupDeleteStatus]);

  useEffect(() => {
    setGroupsToShow(cloneDeep(groups));
  }, [groups]);

  useEffect(() => {
    const selectedGroups = groupsToShow.filter((group) => group.selected);
    setSelectedGroups(selectedGroups);
    setSelectedCount(selectedGroups.length);
  }, [groupsToShow]);

  useEffect(() => {
    updateShowingAddButton();
    updateShowingEditButton();
  }, [selectedCount]);

  function handleOnClickBackground() {
    close();
  }

  function handleOnClickGroupSelectContainer(
    e: React.MouseEvent<HTMLDivElement>
  ) {
    e.stopPropagation();
  }

  function updateShowingAddButton() {
    if (selectedCount <= 1) {
      setShowingAddButton(props.showGroupAddButton);
    } else {
      setShowingAddButton(hasEmptyGroupSelected);
    }
  }

  function updateShowingEditButton() {
    if (
      selectedCount <= 1 ||
      !props.showGroupAddButton ||
      hasEmptyGroupSelected()
    ) {
      setShowingEditButton(false);
    } else {
      setShowingEditButton(true);
    }
  }

  function hasEmptyGroupSelected(): boolean {
    return groupsToShow
      .filter((item) => item.selected)
      .some((item) => item.title === "no_group");
  }

  function getSelectedGroupsTitle(): string[] {
    return groupsToShow
      .filter((item) => item.selected)
      .map((item) => item.title);
  }

  async function fetchGroups() {
    setGroupFetchStatus(Status.Loading);
    try {
      const groups = await fetchGroupsFromAPI(
        props.showAllGroup,
        props.showNoGroup
      );
      setGroups(groups);
      setGroupFetchStatus(Status.Done);
    } catch (error) {
      if (error instanceof Error) {
        alert(error.message);
      } else {
        alert(error);
      }
      setGroupFetchStatus(Status.Ready);
    }
  }

  function searchGroups(searchText: string) {
    const filteredGroups = groups.filter((group) =>
      group.title.toLowerCase().includes(searchText.toLowerCase())
    );

    const newGroups = sortedByNumbers(filteredGroups);
    setGroupsToShow(newGroups);
  }

  function toggleGroupSelected(group: Group) {
    const groupIndex = groupsToShow.findIndex((g) => g.title === group.title);
    let newGroups: Group[] = [];
    switch (props.selectStyle) {
      case "single":
        newGroups = groupsToShow.map((g) => ({
          ...g,
          selected: false,
        }));
        break;
      case "multiple":
        if (group.title === "" || group.title === "all") {
          newGroups = groupsToShow.map((g) => ({
            ...g,
            selected: false,
          }));
        } else {
          newGroups = groupsToShow.map((g) => {
            if (g.title === "" || g.title === "all") {
              return { ...g, selected: false };
            }
            return g;
          });
        }
        break;
    }
    if (groupIndex !== -1) {
      newGroups[groupIndex].selected = !newGroups[groupIndex].selected;
    }
    setGroupsToShow(newGroups);
  }

  function handleOnClickCancleButton() {
    close();
  }

  function handleOnClickDoneButton() {
    const selectedGroups = groupsToShow.filter((group) => group.selected);
    props.handleOnSelectGroups(selectedGroups);
    close();
  }

  function close() {
    setGroups([]);
    props.handleOnClose();
  }

  function handleOnClickGroupAddButton() {
    setOpenGroupAddScreen(true);
  }

  function handleOnClickSelectedGroupEditButton(
    event: React.MouseEvent<HTMLDivElement>
  ) {
    setAnchorEl(event.currentTarget);
    setShowingSelectedEditMenu(true);
  }

  function handleOnCloseMoreMenu() {
    setGroupEditSelectedItem(null);
  }

  function handleOnCloseSelectedMenu() {
    setShowingSelectedEditMenu(false);
  }

  function handleOnCloseEditButton() {
    setShowGroupEditItem(cloneDeep(groupEditSelectedItem));
    setGroupEditSelectedItem(null);
  }

  function handleOnCloseDeleteButton() {
    setShowGroupDeleteItem(cloneDeep(groupEditSelectedItem));
    setGroupEditSelectedItem(null);
  }

  function handleOnClickSelectedGroupMergeButton() {
    setShowingSelectedEditMenu(false);
    setShowingGroupMerge(true);
  }

  function handleOnClickSelectedGroupDeleteButton() {
    setShowingSelectedEditMenu(false);
    setShowingSelectedGroupDeleteDialog(true);
  }

  async function deleteSelectedGroupServerData() {
    setGroupDeleteStatus(Status.Loading);
    try {
      const selectedGroups = getSelectedGroupsTitle();
      await deleteGroupsFromAPI(selectedGroups);
      deleteGroups(selectedGroups);
      setGroupDeleteStatus(Status.Done);
    } catch (error) {
      if (error instanceof Error) {
        alert(error.message);
      } else {
        alert(error);
      }
      setGroupDeleteStatus(Status.Ready);
    }
  }

  async function deleteGroupServerData(group: Group) {
    setGroupDeleteStatus(Status.Loading);
    try {
      await deleteGroupFromAPI(group.title);
      deleteGroup(group);
      setGroupDeleteStatus(Status.Done);
    } catch (error) {
      if (error instanceof Error) {
        alert(error.message);
      } else {
        alert(error);
      }
      setGroupDeleteStatus(Status.Ready);
    }
  }

  function deleteGroup(group: Group) {
    const groupIndex = groups.findIndex((g) => g.title === group.title);
    const newGroups = [...groups];
    if (groupIndex !== -1) {
      newGroups.splice(groupIndex, 1);
    }
    setGroups(newGroups);
  }

  function deleteGroups(selectedGroups: string[]) {
    const newGroups = groups.filter(
      (item) => !selectedGroups.includes(item.title)
    );
    setGroups(newGroups);
  }

  if (props.open === true) {
    return (
      <>
        <div className={styles.stack}>
          <div
            className={styles.container}
            onClick={() => handleOnClickBackground()}
          >
            <div
              className={styles.groupSelectContainer}
              onClick={handleOnClickGroupSelectContainer}
            >
              <div className={styles.searchContainer}>
                <OnevocaSearchInput
                  defaultText={searchDefaultText}
                  hint={t("FindGroupNamePlaceHolderMessage")}
                  handleSearch={(searchText: string) => {
                    if (
                      searchText !== null &&
                      searchText !== undefined &&
                      searchText.length !== 0
                    ) {
                      searchGroups(searchText);
                    } else {
                      setGroupsToShow(cloneDeep(groups));
                    }
                  }}
                />
                {showingAddButton == true && (
                  <div
                    className={styles.groupAddContainer}
                    onClick={() => handleOnClickGroupAddButton()}
                  >
                    <AddRounded
                      style={{ fontSize: 32, color: "var(--white-color)" }}
                    />
                  </div>
                )}
                {showingEditButton == true && (
                  <div
                    className={styles.groupEditContainer}
                    onClick={handleOnClickSelectedGroupEditButton}
                  >
                    <EditRounded
                      style={{ fontSize: 24, color: "var(--primary-color)" }}
                    />
                  </div>
                )}
              </div>
              <div className={styles.listContainer}>
                {groupsToShow.length > 0 &&
                  groupsToShow.map((group, index) => (
                    <GroupSelectCell
                      key={index}
                      group={group}
                      handleOnClickCell={(group: Group) =>
                        toggleGroupSelected(group)
                      }
                      handleOnClickMoreButton={(
                        e: React.MouseEvent<HTMLDivElement>,
                        group: Group
                      ) => {
                        setAnchorEl(e.currentTarget);
                        setGroupEditSelectedItem(group);
                      }}
                    />
                  ))}
                {groups.length === 0 && isLoading === false && (
                  <div className={styles.emptyListTextContainer}>
                    <ListEmptyText text={t("GroupListEmptyLabel")} />
                  </div>
                )}
              </div>
              <div className={styles.buttonContainer}>
                <BigButton
                  title={t("Cancel")}
                  style="disable"
                  handleClick={() => {
                    handleOnClickCancleButton();
                  }}
                />
                <BigButton
                  title={t("SelectDoneButton")}
                  style={selectedCount === 0 ? "disable" : "normal"}
                  handleClick={() => {
                    if (selectedCount !== 0) {
                      handleOnClickDoneButton();
                    }
                  }}
                />
              </div>
            </div>
          </div>
          <GroupAddScreen
            open={openGroupAddScreen === true}
            group={null}
            selectedGroups={null}
            onCreateGroup={() => {
              setToastMessage(t("GroupCreatedToastMessage"));
              fetchGroups();
            }}
            onUpdateGroup={() => {}}
            onClose={() => {
              setOpenGroupAddScreen(false);
            }}
          />
          <GroupAddScreen
            open={showingGroupMerge}
            group={null}
            selectedGroups={selectedGroups}
            onCreateGroup={() => {}}
            onUpdateGroup={() => {
              setToastMessage(t("TermEditedToastMessage"));
              fetchGroups();
            }}
            onClose={() => {
              setShowingGroupMerge(false);
            }}
          />
          <GroupAddScreen
            open={showGroupEditItem !== null}
            group={showGroupEditItem}
            selectedGroups={null}
            onCreateGroup={() => {}}
            onUpdateGroup={() => {
              setToastMessage(t("TermEditedToastMessage"));
              fetchGroups();
            }}
            onClose={() => {
              setShowGroupEditItem(null);
            }}
          />
          <BasicMenu
            isOpen={groupEditSelectedItem !== null}
            anchorEl={anchorEl}
            buttons={moreButtons}
            handleOnClose={() => handleOnCloseMoreMenu()}
          />
          <BasicMenu
            isOpen={showingSelectedEditMenu}
            anchorEl={anchorEl}
            buttons={selectedEditMenu}
            handleOnClose={() => handleOnCloseSelectedMenu()}
          />
          <OnevocaDialog
            open={showingSelectedGroupDeleteDialog}
            title={null}
            message={t("groupdeletemessage")}
            buttons={[
              {
                title: t("Delete"),
                style: "solid",
                onClick: () => {
                  setShowingSelectedGroupDeleteDialog(false);
                  deleteSelectedGroupServerData();
                },
              },
              {
                title: t("Cancel"),
                style: "normal",
                onClick: () => {
                  setShowingSelectedGroupDeleteDialog(false);
                },
              },
            ]}
          />
          <OnevocaDialog
            open={showGroupDeleteItem !== null}
            title={showGroupDeleteItem ? showGroupDeleteItem.title : null}
            message={t("groupdeletemessage")}
            buttons={[
              {
                title: t("Delete"),
                style: "solid",
                onClick: () => {
                  if (showGroupDeleteItem !== null) {
                    setShowGroupDeleteItem(null);
                    deleteGroupServerData(showGroupDeleteItem);
                  }
                },
              },
              {
                title: t("Cancel"),
                style: "normal",
                onClick: () => {
                  setShowGroupDeleteItem(null);
                },
              },
            ]}
          />
          <Snackbar
            open={toastMessage !== null}
            autoHideDuration={3000}
            onClose={() => {
              setToastMessage(null);
            }}
            message={toastMessage}
          />
          {isLoading === true && <LoadingScreen />}
        </div>
      </>
    );
  } else {
    return null;
  }
}
