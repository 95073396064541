import { getUid } from "../auth/AuthManager";
import OnevocaServerError from "../common/OnevocaServerError";
import getApiInstance from "../common/api";
import Term from "../term/Term";
import LearningResult from "./LearningResult";
import { LearningType } from "./LearningType";

function createFinishLearningParameters(
  uid: string,
  learningType: LearningType,
  correctTerms: Term[],
  incorrectTerms: Term[]
): any {
  const parameters: any = {};
  const correctTermParameters: any = {};
  const incorrectTermParameters: any = {};

  parameters["uid"] = uid;

  switch (learningType) {
    case LearningType.flashcards:
      parameters["game_type"] = "flash_card";
      break;
    case LearningType.multiplechoice:
      parameters["game_type"] = "multiple_choice";
      break;
    case LearningType.spellingQuiz:
      parameters["game_type"] = "spell_quiz";
      break;
  }

  correctTerms.forEach((term) => {
    correctTermParameters[term.id] = {
      index: term.index,
      id: term.id,
      exp: term.exp,
      true_and_false: true,
    };
  });

  incorrectTerms.forEach((term) => {
    incorrectTermParameters[term.id] = {
      index: term.index,
      id: term.id,
      exp: term.exp,
      true_and_false: false,
    };
  });

  parameters["words_correct"] = correctTermParameters;
  parameters["words_incorrect"] = incorrectTermParameters;

  return parameters;
}

export async function finishLearning(
  correctTerms: Term[],
  incorrectTerms: Term[],
  learningType: LearningType
): Promise<LearningResult> {
  try {
    const uid = await getUid();
    const parameters = createFinishLearningParameters(
      uid,
      learningType,
      correctTerms,
      incorrectTerms
    );
    const response = await getApiInstance(undefined).post(
      "/learning/updateLearningFinishedTermsLevelAndRecordHistory_v2.php",
      parameters
    );

    const error = response.data.error;
    if (error !== undefined) {
      return Promise.reject(new OnevocaServerError(error));
    }

    const termsData = response.data.data.terms;
    if (termsData === undefined) {
      return Promise.reject(new Error("No data."));
    }

    const terms = termsData.map((termData: any) => Term.fromJson(termData));
    const result = new LearningResult(terms);
    return result;
  } catch (error) {
    return Promise.reject(error);
  }
}
