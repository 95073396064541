import { cloneDeep, delay } from "lodash";
import styles from "./PlaceHolderTextField.module.css";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

interface PlaceHolderTextFieldProps {
  text?: string;
  hint?: string;
  minCount?: number;
  maxCount?: number;
  type?: string;
  lineStyle: "single" | "multiple";
  backgroundColor?: string;
  onGlobalFocus?: boolean;
  onChangeFocus?: (focus: boolean) => void;
  handleTextChange?: (text: string) => void;
  onKeyDownInput?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onKeyDownTextArea?: (event: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  inputRef?: (el: HTMLInputElement | null) => void;
  textAreaRef?: React.Ref<HTMLTextAreaElement>;
  autoFocus?: boolean;
}

function PlaceHolderTextField(props: PlaceHolderTextFieldProps) {
  const { t } = useTranslation();

  const internalTextareaRef = useRef<HTMLTextAreaElement>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [textToSave, setTextToSave] = useState<string>(
    cloneDeep(props.text || "")
  );

  const [onFocus, setOnFocus] = useState(false);

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTextToSave(e.target.value);
    if (props.handleTextChange !== undefined) {
      props.handleTextChange(e.target.value);
    }
  };

  const handleValueChangeForMultiple = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setTextToSave(e.target.value);
    if (props.handleTextChange !== undefined) {
      props.handleTextChange(e.target.value);
    }
  };

  const maxCountClass = classNames(styles.maxCountText, {
    [styles.maxCountNormal]: (props.maxCount ?? 0) >= textToSave.length,
    [styles.maxCountOver]: (props.maxCount ?? 0) < textToSave.length,
  });

  useEffect(() => {
    setTextToSave(cloneDeep(props.text || ""));
  }, [props.text]);

  useEffect(() => {
    if (props.type === "number" && props.maxCount !== undefined) {
      const maxCount = +props.maxCount;
      const value = parseInt(textToSave, 10);
      if (value > maxCount) {
        setTextToSave(maxCount.toString());
      }
    }
  }, [textToSave]);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [textToSave]);

  useEffect(() => {
    const textarea = internalTextareaRef.current;
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  }, [textToSave]);

  useEffect(() => {
    if (props.textAreaRef) {
      if (typeof props.textAreaRef === "function") {
        props.textAreaRef(internalTextareaRef.current);
      } else {
        (
          props.textAreaRef as React.MutableRefObject<HTMLTextAreaElement | null>
        ).current = internalTextareaRef.current;
      }
    }
  }, [props.textAreaRef, internalTextareaRef.current]);

  useEffect(() => {
    if (props.onChangeFocus !== undefined) {
      props.onChangeFocus(onFocus);
    }
  }, [onFocus]);

  return (
    <>
      <div className={styles.container}>
        <div
          className={styles.placeHolderTextField}
          style={{
            backgroundColor:
              props.backgroundColor === null
                ? "var(--button_color)"
                : props.backgroundColor,
          }}
        >
          {props.lineStyle === "single" ? (
            <input
              autoFocus={props.autoFocus}
              className={styles.input}
              value={textToSave}
              placeholder={props.hint}
              type={props.type}
              step={5}
              onChange={handleValueChange}
              onKeyDown={props.onKeyDownInput}
              ref={props.inputRef}
              onFocus={() => {
                setOnFocus(true);
              }}
              onBlur={() => {
                setOnFocus(false);
              }}
            />
          ) : (
            <textarea
              className={styles.textarea}
              value={textToSave}
              placeholder={props.hint}
              onChange={handleValueChangeForMultiple}
              rows={1}
              onKeyDown={props.onKeyDownTextArea}
              ref={internalTextareaRef}
              onFocus={() => {
                setOnFocus(true);
              }}
              onBlur={() => {
                setOnFocus(false);
              }}
            />
          )}
          {props.maxCount !== undefined &&
            onFocus === true &&
            props.type !== "number" && (
              <p
                className={maxCountClass}
              >{`${textToSave.length} / ${props.maxCount}`}</p>
            )}
        </div>
        {props.maxCount !== undefined &&
          textToSave.length > props.maxCount &&
          props.onGlobalFocus === false &&
          props.type !== "number" && (
            <p className={styles.errorMessageText}>
              {t("TextCountOverErrorMessage", { count: props.maxCount })}
            </p>
          )}
        {props.minCount !== undefined &&
          textToSave.length < props.minCount &&
          props.onGlobalFocus === false &&
          props.type !== "number" && (
            <p className={styles.errorMessageText}>
              {t("TextCountLessThanOneMessage")}
            </p>
          )}
      </div>
    </>
  );
}

export default PlaceHolderTextField;
