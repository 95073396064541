import { shuffle } from "lodash";
import Group from "../groups/Group";
import { SortType } from "../settings/SortType";
import Term from "./Term";
import { TermLevel } from "./TermLevel";
import i18n from "i18next";
import { seededShuffle } from "../common/numbers";

export function getFilteredTermsWithQuery(
  query: string,
  fromTerms: Term[]
): Term[] {
  const lowerCaseQuery = query.toLowerCase();
  return fromTerms.filter(
    (term) =>
      term.term.toLowerCase().includes(lowerCaseQuery) ||
      term.definition.toLowerCase().includes(lowerCaseQuery) ||
      term.memo.toLowerCase().includes(lowerCaseQuery) ||
      term.pronunciation.toLowerCase().includes(lowerCaseQuery)
  );
}

export function deleteAndReturnTerm(terms: Term[], term: Term): Term[] {
  const termIndex = terms.findIndex((t) => t.id === term.id);
  if (termIndex !== -1) {
    const newTerms = [...terms];
    newTerms.splice(termIndex, 1);
    return newTerms;
  } else {
    return terms;
  }
}

export function updateAndReturnTerm(terms: Term[], term: Term): Term[] {
  const termIndex = terms.findIndex((t) => t.id === term.id);
  if (termIndex !== -1) {
    const newTerms = [...terms];
    newTerms[termIndex] = term;
    return newTerms;
  } else {
    return terms;
  }
}

export function updateAndReturnTermLevel(
  terms: Term[],
  term: Term,
  toLevel: TermLevel,
  toExp: number
): Term[] {
  const termIndex = terms.findIndex((t) => t.id === term.id);
  if (termIndex !== -1) {
    const newTerms = [...terms];
    const updatedTerm = {
      ...newTerms[termIndex],
      level: toLevel,
      exp: toExp,
    };
    newTerms[termIndex] = Term.fromJson(updatedTerm);
    return newTerms;
  } else {
    return terms;
  }
}

export function getFilteredTermsWithOptions(
  terms: Term[],
  seed?: number,
  sortType?: SortType,
  selectedGroups?: Group[],
  selectedLevels?: TermLevel[]
): Term[] {
  let filteredTerms: Term[] = [];
  if (selectedGroups !== undefined && selectedGroups.length !== 0) {
    filteredTerms = selectedGroups.reduce<Term[]>((acc, group) => {
      const groupTerms = terms.filter((term) => term.group === group.title);
      return acc.concat(groupTerms);
    }, []);
  } else {
    filteredTerms = [...terms];
  }

  if (selectedLevels !== undefined) {
    filteredTerms = filteredTerms.filter((term) =>
      selectedLevels.includes(term.level)
    );
  }

  if (seed !== undefined) {
    filteredTerms = seededShuffle(filteredTerms, seed);
  } else if (sortType !== undefined) {
    switch (sortType) {
      case SortType.recent:
        break;
      case SortType.old:
        filteredTerms.reverse();
        break;
      case SortType.abc:
        filteredTerms.sort((a, b) => a.term.localeCompare(b.term));
        break;
      case SortType.random:
        let shuffledTerms: Term[] = [];
        if (seed !== undefined) {
          shuffledTerms = seededShuffle(filteredTerms, seed);
        } else {
          shuffledTerms = shuffle(filteredTerms);
        }
        filteredTerms = shuffledTerms;
        break;
    }
  }

  return filteredTerms;
}

export function convertSortTypeString(sortType: SortType): string {
  switch (sortType) {
    case SortType.recent:
      return "asc";
    case SortType.old:
      return "desc";
    case SortType.abc:
      return "abc";
    case SortType.random:
      return "random";
  }
}

export function makeSortTypeString(sortType: SortType): string {
  switch (sortType) {
    case SortType.recent:
      return i18n.t("Latest");
    case SortType.old:
      return i18n.t("Old");
    case SortType.abc:
      return i18n.t("Alphabet");
    case SortType.random:
      return i18n.t("Random");
  }
}

export function isIncludeLevel(level: TermLevel, from: TermLevel[]): boolean {
  return from.includes(level);
}

export function toggleTermLevel(
  checked: boolean,
  level: TermLevel,
  from: TermLevel[]
): TermLevel[] {
  if (checked) {
    if (!from.includes(level)) {
      return [...from, level];
    }
  } else {
    if (from.includes(level)) {
      return from.filter((item) => item !== level);
    }
  }
  return from;
}

export function makeSelectedLevelsString(
  selectedLevels: TermLevel[],
  style?: "learning"
): string {
  if (style === "learning") {
    const levels = [];
    if (selectedLevels.includes(TermLevel.difficult)) {
      levels.push(i18n.t("Difficult"));
    }

    if (selectedLevels.includes(TermLevel.familiar)) {
      levels.push(i18n.t("Familiar"));
    }

    if (selectedLevels.includes(TermLevel.perfect)) {
      levels.push(i18n.t("Perfect"));
    }

    const resultString = levels.join(", ");
    return resultString;
  } else {
    if (
      selectedLevels.includes(TermLevel.difficult) === true &&
      selectedLevels.includes(TermLevel.familiar) === true &&
      selectedLevels.includes(TermLevel.perfect) === true
    ) {
      if (style === "learning") {
        return `${i18n.t("Difficult")}, ${i18n.t("Familiar")}, ${i18n.t(
          "Perfect"
        )}`;
      } else {
        return i18n.t("LevelFilterAllTerms");
      }
    } else if (
      selectedLevels.includes(TermLevel.difficult) === true &&
      selectedLevels.includes(TermLevel.familiar) === true &&
      selectedLevels.includes(TermLevel.perfect) === false
    ) {
      return i18n.t("LevelFilterLearning");
    } else if (
      selectedLevels.includes(TermLevel.difficult) === false &&
      selectedLevels.includes(TermLevel.familiar) === false &&
      selectedLevels.includes(TermLevel.perfect) === true
    ) {
      return i18n.t("LevelFilterMemorized");
    } else if (
      selectedLevels.includes(TermLevel.difficult) === true &&
      selectedLevels.includes(TermLevel.familiar) === false &&
      selectedLevels.includes(TermLevel.perfect) === false
    ) {
      return i18n.t("LevelFilterDifficult");
    } else if (
      selectedLevels.includes(TermLevel.difficult) === false &&
      selectedLevels.includes(TermLevel.familiar) === true &&
      selectedLevels.includes(TermLevel.perfect) === false
    ) {
      return i18n.t("LevelFilterFamiliar");
    } else {
      return "Wrong value.";
    }
  }
}
